import React, { useEffect, useState, useCallback, useRef } from "react";
import { OrderStatusContext } from "./OrderStatusContext";
import IOrderInfo from "../portal/order-processing/interfaces/IOrderInfo";
import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { ZenObservable } from "zen-observable-ts";
import IOrderShipmentInfo from "../portal/order-processing/interfaces/IOrderShipmentInfo";
import { CloneOrderEvent, GenerateOrderEventFromShipment, ParseOrderEvent, ParseShipmentEvent } from "../helpers/EventHelper";

type Props = {
  children: React.ReactNode;
};

export const OrderStatusContextProvider = ({ children }: Props) => {
  const [createdOrders, setCreatedOrders] = useState<IOrderInfo[]>([]);
  const [processedOrders, setProcessedOrders] = useState<IOrderInfo[]>([]);
  const [fulfilledOrders, setFulfilledOrders] = useState<IOrderInfo[]>([]);
  const [preparedShipmentGroups, setPreparedShipmentGroups] = useState<IOrderInfo[]>([]);
  const [processedShipmentGroups, setProcessedShipmentGroups] = useState<IOrderInfo[]>([]);
  const [orderEventNotification, setOrderEventNotification] = useState<IOrderInfo>();
  const [shipmentEventNotification, setShipmentEventNotification] = useState<IOrderShipmentInfo>();
  const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);
  const [webSocketConnection, setWebSocketConnection] = useState<ZenObservable.Subscription>();
  const [webSocketAllEventsConnected, setWebSocketAllEventsConnected] = useState<boolean>(false);
  const [webSocketAllEventsConnection, setWebSocketAllEventsConnection] = useState<ZenObservable.Subscription>();
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [featureFlagAllEvents, setFeatureFlagAllEvents] = useState<boolean>(false);

  const addCreatedOrders = async (order: IOrderInfo) => {
    try {
      setCreatedOrders([order].concat(createdOrders));
    } catch (error) {
      alert(error);
    }
  };

  const removeCreatedOrders = async (order: IOrderInfo) => {
    var items = createdOrders.filter((item) => item.orderId !== order.orderId);
    setCreatedOrders(items);
  };

  const addProcessedOrders = async (order: IOrderInfo) => {
    try {
      setProcessedOrders([order].concat(processedOrders));
    } catch (error) {
      alert(error);
    }
  };

  const removeProcessedOrders = async (order: IOrderInfo) => {
    var items = processedOrders.filter((item) => item.orderId !== order.orderId);
    setProcessedOrders(items);
  };

  const addPreparedShipments = async (shipment: IOrderShipmentInfo) => {
    try {
      var preparedShipmentGroup = preparedShipmentGroups.find((p) => p.orderId === shipment.orderId);
      if (preparedShipmentGroup) {
        //console.log("Existing group");
        var existingShipment = preparedShipmentGroup.shipments.find((element) => {
          return element.shipmentId === shipment.shipmentId;
        });
        if (existingShipment) {
          console.log("Shipment already exists");
          return;
        }

        preparedShipmentGroup.shipments = [shipment]
          .concat(preparedShipmentGroup.shipments)
          .sort(function (a: IOrderShipmentInfo, b: IOrderShipmentInfo) {
            if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
            if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
            return 0;
          });
        await refreshPreparedShipments(preparedShipmentGroup);
      } else {
        //console.log("New group");
        var processedOrder = processedOrders.find((element) => {
          return element.orderId === shipment.orderId;
        });
        if (processedOrder) {
          preparedShipmentGroup = CloneOrderEvent(processedOrder, "ShipmentPrepared");
          preparedShipmentGroup.shipments = [shipment].concat(preparedShipmentGroup.shipments);
          setPreparedShipmentGroups([preparedShipmentGroup].concat(preparedShipmentGroups));
          //console.log(preparedShipmentGroup);
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const refreshPreparedShipments = async (preparedShipmentGroup: IOrderInfo) => {
    try {
      var index = preparedShipmentGroups.indexOf(preparedShipmentGroup);
      var orderId = preparedShipmentGroup.orderId;
      var updatedList = preparedShipmentGroups.filter((item) => item.orderId !== orderId);
      updatedList.splice(index, 0, preparedShipmentGroup);
      setPreparedShipmentGroups(updatedList);
    } catch (error) {
      alert(error);
    }
  };

  const removePreparedShipments = async (shipment: IOrderShipmentInfo) => {
    var preparedShipmentGroup = preparedShipmentGroups.find((element) => {
      return element.orderId === shipment.orderId;
    });
    if (preparedShipmentGroup) {
      preparedShipmentGroup.shipments = preparedShipmentGroup.shipments.filter((item) => item.shipmentId !== shipment.shipmentId);
      // console.log(preparedShipmentGroup.shipments);
      if (preparedShipmentGroup.shipments.length > 0) {
        // console.log("removePreparedShipments-Many shipments");
        await refreshPreparedShipments(preparedShipmentGroup);
      } else {
        // console.log("removePreparedShipments-One shipment");
        var items = preparedShipmentGroups.filter((item) => item.orderId !== shipment.orderId);
        setPreparedShipmentGroups(items);
      }
    }
  };

  const addProcessedShipments = async (shipment: IOrderShipmentInfo) => {
    try {
      var processedShipmentGroup = processedShipmentGroups.find((element) => {
        return element.orderId === shipment.orderId;
      });
      if (processedShipmentGroup) {
        // console.log("addProcessedShipments-Existing group");
        var existingShipment = processedShipmentGroup.shipments.find((element) => {
          return element.shipmentId === shipment.shipmentId;
        });
        if (existingShipment) {
          // console.log("Shipment already exists");
          return;
        }
        processedShipmentGroup.shipments = [shipment].concat(processedShipmentGroup.shipments);
        await refreshProcessedShipments(processedShipmentGroup);
      } else {
        // console.log("addProcessedShipments-New group");
        var preparedShipmentGroup = preparedShipmentGroups.find((element) => {
          return element.orderId === shipment.orderId;
        });

        if (preparedShipmentGroup) {
          processedShipmentGroup = CloneOrderEvent(preparedShipmentGroup, "ShipmentProcessed");
          processedShipmentGroup.shipments = [shipment].concat(processedShipmentGroup.shipments);
          setProcessedShipmentGroups([processedShipmentGroup].concat(processedShipmentGroups));
        } else {
          processedShipmentGroup = GenerateOrderEventFromShipment(shipment, "ShipmentProcessed");
          setProcessedShipmentGroups([processedShipmentGroup].concat(processedShipmentGroups));
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const refreshProcessedShipments = async (processedShipmentGroup: IOrderInfo) => {
    try {
      var index = processedShipmentGroups.indexOf(processedShipmentGroup);
      var orderId = processedShipmentGroup.orderId;
      var updatedList = processedShipmentGroups.filter((item) => item.orderId !== orderId);
      updatedList.splice(index, 0, processedShipmentGroup);
      setProcessedShipmentGroups(updatedList);
    } catch (error) {
      alert(error);
    }
  };

  const clearPreparedShipments = async (order: IOrderInfo) => {
    var items = preparedShipmentGroups.filter((item) => item.orderId !== order.orderId);
    setPreparedShipmentGroups(items);
  };

  const clearProcessedShipments = async (order: IOrderInfo) => {
    var items = processedShipmentGroups.filter((item) => item.orderId !== order.orderId);
    setProcessedShipmentGroups(items);
  };

  const addFulfilledOrders = async (order: IOrderInfo) => {
    try {
      setFulfilledOrders([order].concat(fulfilledOrders));
    } catch (error) {
      alert(error);
    }
  };

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        applyUserName();
        //console.info("user signed in");
        break;
    }
  });

  const applyUserName = async () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.currentUserInfo().then((info) => {
          setUserId(info.attributes["sub"]);
          setUserName(info.attributes["given_name"] + " " + info.attributes["family_name"]);
        });
      })
      .catch((err) => {
        //console.error(err);
      });
  };

  useEffect(() => {
    // console.info("Store Context Initialized");
    console.log("Context initializing");
    applyUserName();
    Hub.remove("auth", listener);
    Hub.listen("auth", listener);

    if (!webSocketConnected) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          establishConnection(0);
        })
        .catch((err) => {
          //console.error(err);
        });
    }

    return () => Hub.remove("auth", listener);
  }, []);

  const processOrderEvents = async (eventNotification: IOrderInfo) => {
    switch (eventNotification.eventType) {
      case "OrderCreated":
        addCreatedOrders(eventNotification);
        return;
      case "OrderProcessed":
        removeCreatedOrders(eventNotification);
        addProcessedOrders(eventNotification);
        return;
      case "OrderFulfilled":
        removeProcessedOrders(eventNotification);
        clearPreparedShipments(eventNotification);
        clearProcessedShipments(eventNotification);
        addFulfilledOrders(eventNotification);
        return;
    }
  };

  const processShipmentEvents = async (eventNotification: IOrderShipmentInfo) => {
    switch (eventNotification.eventType) {
      case "ShipmentPrepared":
        addPreparedShipments(eventNotification);
        return;
      case "ShipmentProcessed":
        removePreparedShipments(eventNotification);
        addProcessedShipments(eventNotification);
        return;
    }
  };

  useEffect(() => {
    if (orderEventNotification) {
      processOrderEvents(orderEventNotification);
    }
    return;
  }, [orderEventNotification]);

  useEffect(() => {
    if (shipmentEventNotification) {
      processShipmentEvents(shipmentEventNotification);
    }
    return;
  }, [shipmentEventNotification]);

  const onAllEventsIotError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    PubSub.removePluggable("AWSIoTProvider");
    establishAllEventsConnection(5000);
  };

  async function establishAllEventsConnection(delay: number) {
    await timeout(delay);
    subscribeToAllEventsIotTopic();
  }

  const subscribeToAllEventsIotTopic = () => {
    console.info("Connecting to IoT Core for all users");

    if (webSocketAllEventsConnection) {
      webSocketAllEventsConnection.unsubscribe();
    }

    const iotProvider = new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_PUB_SUB_REGION,
      aws_pubsub_endpoint: process.env.REACT_APP_PUB_SUB_ENDPOINT,
    });

    PubSub.addPluggable(iotProvider);

    setWebSocketAllEventsConnection(
      PubSub.subscribe("order-status/all-admin-events").subscribe({
        next: (data) => onOrderEvent(data),
        error: (error) => onAllEventsIotError(error),
        complete: () => console.log("Done"),
      })
    );

    setWebSocketAllEventsConnected(true);
    console.info("Connected to IoT Core for all users");
  };

  const unSubscribeFromAllEventsIotTopic = () => {
    PubSub.removePluggable("AWSIoTProvider");

    if (webSocketAllEventsConnection) {
      webSocketAllEventsConnection.unsubscribe();
      console.log("Unsubscribed from All Events");
    }

    setWebSocketAllEventsConnected(false);
  };

  useEffect(() => {
    if (featureFlagAllEvents) {
      if (!webSocketAllEventsConnected) {
        unSubscribeFromIotTopic();
        subscribeToAllEventsIotTopic();
      }
    } else {
      if (webSocketAllEventsConnected) {
        unSubscribeFromAllEventsIotTopic();
        subscribeToIotTopic();
      }
    }
  }, [featureFlagAllEvents]);

  const onOrderEvent = (data: string) => {
    console.info(data);
    const messageData = JSON.parse(JSON.stringify(data));

    const eventType: string = messageData.value.eventType;
    const niceMessage = getNiceMessage(eventType);

    if (eventType.startsWith("Order")) {
      var orderEventInfo: IOrderInfo = ParseOrderEvent(messageData);

      orderEventInfo["message"] = niceMessage;

      setOrderEventNotification(orderEventInfo);
    } else {
      var shipmentEventInfo: IOrderShipmentInfo = ParseShipmentEvent(messageData);
      shipmentEventInfo["message"] = niceMessage;
      setShipmentEventNotification(shipmentEventInfo);
    }
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const onIotError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    PubSub.removePluggable("AWSIoTProvider");
    establishConnection(5000);
  };

  async function establishConnection(delay: number) {
    await timeout(delay);
    subscribeToIotTopic();
  }

  const subscribeToIotTopic = () => {
    Auth.currentUserInfo().then((info) => {
      const userId = info.attributes["sub"];
      console.info("Connecting to IoT Core for user " + userId);

      const iotProvider = new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_PUB_SUB_REGION,
        aws_pubsub_endpoint: process.env.REACT_APP_PUB_SUB_ENDPOINT,
      });

      PubSub.addPluggable(iotProvider);

      if (webSocketConnection) {
        webSocketConnection.unsubscribe();
      }

      setWebSocketConnection(
        PubSub.subscribe("order-status/" + userId).subscribe({
          next: (data) => onOrderEvent(data),
          error: (error) => onIotError(error),
          complete: () => console.log("Done"),
        })
      );

      setWebSocketConnected(true);
      console.info("Connected to IoT Core for user " + userId);
    });
  };

  const unSubscribeFromIotTopic = () => {
    console.log(webSocketConnection);
    PubSub.removePluggable("AWSIoTProvider");

    if (webSocketConnection) {
      webSocketConnection.unsubscribe();
      console.log("Unsubscribed from User Events");
    }

    setWebSocketConnected(false);
  };

  const listener = (data: any) => {
    console.info(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        subscribeToIotTopic();
        break;
    }
  };

  const getNiceMessage = (eventType: string) => {
    switch (eventType) {
      case "OrderCreated":
        return "We received your order, it is being processed.";
      case "OrderProcessed":
        return "We processed your order and will ship it to you soon.";
      case "OrderFulfilled":
        return "Your order has been fulfilled, we processed all order shipments.";
      case "ShipmentPrepared":
        return "We packing a part of your order and will ship it to you soon.";
      case "ShipmentProcessed":
        return "We shipped a part of your order, you can track it using provided tracking number.";
      default:
        return "Generic Notification";
    }
  };

  return (
    <OrderStatusContext.Provider
      value={{
        processOrderEvents,
        processShipmentEvents,
        createdOrders,
        processedOrders,
        preparedShipmentGroups,
        processedShipmentGroups,
        fulfilledOrders,
        userId,
        userName,
        setUserName,
        featureFlagAllEvents,
        setFeatureFlagAllEvents,
      }}
    >
      {children}
    </OrderStatusContext.Provider>
  );
};
