import { Variants } from "framer-motion";

const orderEventAnimation: Variants = {
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      when: "beforeChildren",
      damping: 10,
      stiffness: 80,
      duration: 0.1,
    },
  },
  initial: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      damping: 10,
      stiffness: 80,
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      when: "afterChildren",
      damping: 10,
      stiffness: 80,
      duration: 0.1,
    },
  },
};

const shipmentEventAnimation: Variants = {
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      damping: 10,
      stiffness: 70,
      duration: 0.1,
    },
  },
  initial: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      damping: 10,
      stiffness: 70,
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0,
    transition: {
      type: "spring",
      damping: 10,
      stiffness: 70,
      duration: 0.1,
    },
  },
};

export const GetOrderEventAnimation = () => {
  return orderEventAnimation;
};

export const GetShipmentEventAnimation = () => {
  return shipmentEventAnimation;
};
