/* eslint-disable  */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsExports = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:e6365b10-1d11-41bd-be93-81ec68c7b351",
  aws_user_pools_id: "us-east-1_SKB98FEEX",
  aws_user_pools_web_client_id: "4o8suegoufpfp4485j7emjl9ue",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "given_name", "family_name"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsExports;
