import React from "react";
import IOrderInfo from "../portal/order-processing/interfaces/IOrderInfo";
import IOrderShipmentInfo from "../portal/order-processing/interfaces/IOrderShipmentInfo";

type OrderStatusContextType = {
  userId: string;
  userName: string;
  createdOrders: IOrderInfo[];
  processedOrders: IOrderInfo[];
  fulfilledOrders: IOrderInfo[];
  preparedShipmentGroups: IOrderInfo[];
  processedShipmentGroups: IOrderInfo[];
  processOrderEvents: (value: IOrderInfo) => void;
  processShipmentEvents: (value: IOrderShipmentInfo) => void;
  setUserName: (value: string) => void;
  featureFlagAllEvents: boolean;
  setFeatureFlagAllEvents: (value: boolean) => void;
};

export const OrderStatusContext = React.createContext<OrderStatusContextType | undefined>(undefined);

export const useOrderStatusContext = () => React.useContext(OrderStatusContext);
