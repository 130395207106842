import { motion } from "framer-motion";
import uniqolor from "uniqolor";
import IFulfilledOrderCard from "../interfaces/IFulfilledOrderCard";
import { GetOrderEventAnimation } from "../../../helpers/AnimationHelper";
import "./FulfilledOrderCard.css";

export default function FulfilledOrderCard({ orderDetails }: IFulfilledOrderCard): JSX.Element {
  return (
    <motion.div
      className="fulfilled-order-event-pane"
      layout
      key={orderDetails.orderId}
      variants={GetOrderEventAnimation()}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div
        className="fulfilled-order-event-container"
        style={{
          backgroundColor: uniqolor(orderDetails.orderId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="fulfilled-order-event-vertical-segment">
          <span className="fulfilled-order-event-attribute-label">Order Id:</span>
          <span className="fulfilled-order-event-id-label">{orderDetails.orderId.substring(0, 18)}</span>
        </div>
        <div className="fulfilled-order-event-horizontal-delimiter" />
        <div className="fulfilled-order-event-vertical-segment">
          <span className="fulfilled-order-event-attribute-label">Event Date:</span>
          <span className="fulfilled-order-event-date-label fulfilled-order-event-padding-bottom">
            {new Intl.DateTimeFormat("us-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(orderDetails.eventDate))}
          </span>
        </div>
      </div>
    </motion.div>
  );
}
