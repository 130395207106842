import { useState, useEffect } from "react";
import IProfileManager from "./interfaces/IProfileManager";
import Table from "react-bootstrap/Table";
import { Auth } from "aws-amplify";
import "./ProfileManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import IUserInfo from "./interfaces/IUserInfo";
import { useOrderStatusContext } from "../../contexts/OrderStatusContext";
import { CheckboxField } from "@aws-amplify/ui-react";
import { Form } from "react-bootstrap";

export default function ProfileManager(props: IProfileManager): JSX.Element {
  const orderStatusContext = useOrderStatusContext()!;
  const [userInfo, setUserInfo] = useState<IUserInfo>();

  useEffect(() => {
    async function getUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log("attributes:", user.attributes);
        setUserInfo({
          email: user.attributes.email,
          emailVerified: user.attributes.email_verified,
          firstName: user.attributes.given_name,
          lastName: user.attributes.family_name,
        });
      } catch (error) {
        console.log(error);
      }
    }

    getUserInfo();
    // console.log(userInfo);
  }, []);

  const getVerificationIcon = (verificationStatus: Boolean | undefined) => {
    if (verificationStatus) {
      return faSquareCheck;
    } else {
      return faBan;
    }
  };

  const onAllEventsFeatureHandler = async () => {
    if (orderStatusContext.featureFlagAllEvents) {
      orderStatusContext.setFeatureFlagAllEvents(false);
    } else {
      orderStatusContext.setFeatureFlagAllEvents(true);
    }
  };

  return (
    <div className="profile-container">
      <section className="profile-section">
        <span className="profile-label">Your Profile:</span>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td className="profile-table-bold">First Name</td>
              <td>{userInfo?.firstName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Last Name</td>
              <td>{userInfo?.lastName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email</td>
              <td>{userInfo?.email}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email Verified</td>
              <td className="profile-table-column-center">
                <FontAwesomeIcon icon={getVerificationIcon(userInfo?.emailVerified)} color="orange" />
              </td>
            </tr>
            <tr>
              <td className="profile-table-bold">
                Show All Events(<span className="profile-table-text-red">Experimental!!!</span>)
              </td>
              <td className="profile-table-column-center">
                <Form.Check
                  className="profile-table-checkbox"
                  type="switch"
                  id="custom-switch"
                  checked={orderStatusContext.featureFlagAllEvents}
                  onChange={onAllEventsFeatureHandler}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </section>
    </div>
  );
}
